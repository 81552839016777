import React from 'react'
import { Layout, Button } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import styles from './style.module.scss'
import FlexSearch from 'flexsearch'
import { ReactComponent as LogInIcon } from './assets/LoginIcon.svg'
import { NavBar } from './navBar'
import { Col, Row } from 'antd';
import RegisterProfileMenu from 'layouts/Login/RegisterProfileMenu'

//@withRouter
class LoginLayout extends React.PureComponent {
  // state = {
  //   backgroundNumber: 1,
  //   backgroundEnabled: false,
  // }

  // changeBackground = () => {
  //   const { backgroundNumber } = this.state
  //   this.setState({
  //     backgroundEnabled: true,
  //     backgroundNumber: backgroundNumber === 5 ? 1 : backgroundNumber + 1,
  //   })
  // }

  // toggleBackground = () => {
  //   const { backgroundEnabled } = this.state
  //   this.setState({
  //     backgroundEnabled: !backgroundEnabled,
  //   })
  // }

  render() {
    const { children } = this.props
   
    return (
      <Layout>
        <Layout.Content style={{ "paddingTop": 0 }}>
          <div className={`${styles.layout}`}>
            <div className={styles.content}>
              {/* <div className={styles.navBarContainer}>
                <Row justify="space-between" align="middle">
                  <Col span={23}>
                  </Col>
                  <Col span={1}>
                      <RegisterProfileMenu />
                  </Col>
                </Row>
              </div> */}
              {children}
            </div>
          </div>
        </Layout.Content>
      </Layout>
    )
  }
}

export default withRouter(LoginLayout)
