const actions = {
  GET_SOURCES: 'surfserv/GET_SOURCES',
  GET_AUDIENCES: 'surfserv/GET_AUDIENCES',
  LOAD_AUDIENCE_DETAIL: 'surfserv/LOAD_AUDIENCE_DETAIL',
  SET_STATE: 'surfserv/SET_STATE',
  LOAD_EVENT_STAT: 'surfserv/LOAD_EVENT_STAT',
  LOAD_EVENT_DATA: 'surfserv/LOAD_EVENT_DATA',
  LOAD_EVENT_FOR_SOURCE: 'surfserv/LOAD_EVENT_FOR_SOURCE',
  LOAD_EVENT_FOR_CAMPAIGN: 'surfserv/LOAD_EVENT_FOR_CAMPAIGN',
  LOAD_SURFSIDE_AUDIENCE_INDEX:'surfserv/LOAD_SURFSIDE_AUDIENCE_INDEX',
  LOAD_EVENT_COUNT_FOR_CAMPAIGN_PER_SLOT: 'surfserv/LOAD_EVENT_COUNT_FOR_CAMPAIGN_PER_SLOT',
  LOAD_EVENT_LOCATIONS: 'surfserv/LOAD_EVENT_LOCATIONS'
}

export default actions
