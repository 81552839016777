import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { UserOutlined } from '@ant-design/icons'
import { Menu, Dropdown, Avatar, Badge } from 'antd'
import { FormattedMessage } from 'react-intl'
import styles from './style.module.scss'
import { useAuth0 } from '../../../auth0'
import { ReactComponent as LogInIcon } from '../assets/LoginIcon.svg'

const Logout = ({ dispatchLogout, path }) => {
  const { logout } = useAuth0()

  return (
    <a
      href="javascript: void(0);"
      onClick={() => {
        dispatchLogout()
        logout({ returnTo: window.location.origin })
      }}
    >
      <i className={`${styles.menuIcon} icmn-exit`} />
      <FormattedMessage id="topBar.profileMenu.logout" />
    </a>
  )
}

const mapStateToProps = ({ user }) => ({ user })
/*
@withRouter
@connect(({ user }) => ({ user })) */
class ProfileMenu extends React.Component {
  logout = () => {
    const { dispatch } = this.props
    dispatch({
      type: 'user/LOGOUT',
    })
  }

  render() {
    const {
      user,
      location: { pathname },
    } = this.props
    const menu = (
      <Menu selectable={false}>
        <Menu.Item>
          <strong>
            <FormattedMessage id="topBar.profileMenu.hello" />, {user.name || 'Anonymous'}
          </strong>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item>
          <Logout dispatchLogout={this.logout} path={pathname} />
        </Menu.Item>
      </Menu>
    )
    return (
      <Dropdown overlay={menu} trigger={['click']} onVisibleChange={this.addCount}>
        <div>
            <LogInIcon />
          </div>
      </Dropdown>
    )
  }
}

export default withRouter(connect(mapStateToProps)(ProfileMenu))
