import React from 'react'

const CreateIcon = props => (
  <svg width={24} height={24} {...props} viewBox="0 0 261 261"  xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd" transform="translate(5 5)">
      <path d="m179.333333 115.333333h-42.666666v-42.6666663c0-5.8311111-4.764445-10.6666667-10.666667-10.6666667-5.831111 0-10.666667 4.8355556-10.666667 10.6666667v42.6666663h-42.6666663c-5.8311111 0-10.6666667 4.835556-10.6666667 10.666667 0 5.902222 4.8355556 10.666667 10.6666667 10.666667h42.6666663v42.666666c0 5.902223 4.835556 10.666667 10.666667 10.666667 5.902222 0 10.666667-4.764444 10.666667-10.666667v-42.666666h42.666666c5.902223 0 10.666667-4.764445 10.666667-10.666667 0-5.831111-4.764444-10.666667-10.666667-10.666667z" fill="#fff" fillRule="nonzero"/>
      <path d="m251.225201 125.6126c0 69.375056-56.237545 125.616768-125.612601 125.616768-69.375055 0-125.6126-56.241712-125.6126-125.616768 0-69.3708883 56.237545-125.6126 125.6126-125.6126 69.375056 0 125.612601 56.2417117 125.612601 125.6126z" stroke="#fff" strokeWidth="8.33334"/>
    </g>
  </svg>
)

export default CreateIcon
