import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import ProfileMenu from './ProfileMenu'
import styles from './style.module.scss'
import Logo from './Logo'

const mapStateToProps = (state) => {
  state = state || { menu: {} }

  return {
    isOpen: (state.menu || {}).isOpen,
  }
}

/* @withRouter
@connect(mapStateToProps) */
class TopBar extends React.Component {
  render() {
    const { isOpen } = this.props
    return (
      <div className={[`${styles.topbar} ${isOpen ? styles.isCollapsed : ''}`]}>
        <div className="col-xl-11">
          <Link to="/home" className={styles.logoLink}>
            <Logo />
          </Link>
        </div>

        <div className={`col-xl-1 ${styles.toRight}`}>
          <ProfileMenu />
        </div>
      </div>
    )
  }
}

export default withRouter(connect(mapStateToProps)(TopBar))
