import actions from './actions'

const initialState = {
  audience: [],
  audience_by_id:{
    isFetching:true
  },
  destination_modal:false
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_AUDIENCE:
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_AUDIENCE:
      return { ...state, audience: action.payload.data }
    case actions.SET_AUDIENCE_BY_ID:
      return { ...state, audience_by_id: action.payload.data }
    case actions.OPEN_DESTINATION_MODAL: 
      return { ...state, destination_modal:action.payload}
    default:
      return state
  }
}
