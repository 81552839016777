import actions from './actions'

const initialState = {
  targets: [],
  target_by_id:{}
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.LOAD_TARGETS:
      return { ...state, targets: action.payload.data }
    case actions.LOAD_TARGET_BY_ID:
      return { ...state, target_by_id: action.payload.data }
    default:
      return state
  }
}
