import { all, takeEvery, put, call, select } from 'redux-saga/effects'
// import { notification } from 'antd'
import {
  getEventStats,
  getSources,
  getAudienceDetail,
  postEventStatsForCampaign,
  getAudiencesFromServices,
  getSurfsideAudienceIndex,
  getEventLocations

} from 'services/surfside-services'
// getEventStats
import { accounts } from './selector'
import actions from './actions'

export function* GET_SOURCES({ payload = {}}) {
  
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true
    },
  })
  const sources = yield call(getSources, payload)
  const { data } = sources
  yield put({
    type: 'surfserv/SET_STATE',
    payload: {
      sources: [...data],
    },
  })

  const accountsVar = yield select(accounts)

  yield put({
    type: 'surfserv/SET_STATE',
    payload: {
      accounts: accountsVar,
    },
  })
}

export function* GET_AUDIENCES() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const audiences = yield call(getAudiencesFromServices)
  const { data } = audiences
  yield put({
    type: 'surfserv/SET_STATE',
    payload: {
      audiences: [...data],
    },
  })
}

export function* LOAD_EVENT_STAT(payload = {}) {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(getEventStats, payload)
  const { data } = response
  if (response) {
    //  const { uid: id, email, photoURL: avatar } = response
    yield put({
      type: 'surfserv/SET_STATE',
      payload: {
        ...data,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOAD_EVENT_DATA({ payload, namespace }) {
  namespace = namespace || 'currentCampaign'
  yield put({
    type: 'surfserv/SET_STATE',
    payload: {
      loading: true,
      loadingCampaignDetail: true,
    }
  })

  const response = yield call(postEventStatsForCampaign, payload)
  const { data } = response

  if (response) {
    yield put({
      type: 'surfserv/SET_STATE',
      payload: {
        [namespace]: { ...data },
      },
    })
  }

  yield put({
    type: 'surfserv/SET_STATE',
    payload: {
      loading: false,
      loadingCampaignDetail: false,
    },
  })
}
export function* LOAD_EVENT_LOCATIONS({ payload }) {
  yield put({
    type: 'surfserv/SET_STATE',
    payload: {
      loading: true,
    }
  })

  const response = yield call(getEventLocations, payload)
  const { data } = response

  if (response) {
    yield put({
      type: 'surfserv/SET_STATE',
      payload: {
        currentLocations: { ...data },
      },
    })
  }
  
  yield put({
    type: 'surfserv/SET_STATE',
    payload: {
      loading: false,
      loadingCampaignDetail: false,
    }
  })
}

export function* LOAD_SURFSIDE_AUDIENCE_INDEX({ payload }) {
  yield put({
    type: 'surfserv/SET_STATE',
    payload: {
      loading: true,
    }
  })

  const response = yield call(getSurfsideAudienceIndex, payload)
  const { data } = response

  if (response) {
    yield put({
      type: 'surfserv/SET_STATE',
      payload: {
        currentSurfsideAudience: { ...data },
      },
    })
  }
  
  yield put({
    type: 'surfserv/SET_STATE',
    payload: {
      loading: false
    }
  })
}



export function* LOAD_AUDIENCE_DETAIL({ payload }) {
  yield put({
    type: 'surfserv/SET_STATE',
    payload: {
      loading: true,
      loadingAudienceDetail: true,
    },
  })
  const { audienceId } = payload
  const response = yield call(getAudienceDetail, audienceId)
  const { data } = response

  if (response) {
    yield put({
      type: 'surfserv/SET_STATE',
      payload: {
        currentAudience: { ...data },
      },
    })
  }

  yield put({
    type: 'surfserv/SET_STATE',
    payload: {
      loading: false,
      loadingAudienceDetail: false,
    },
  })
}



export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_SOURCES, GET_SOURCES),
    takeEvery(actions.GET_AUDIENCES, GET_AUDIENCES),
    takeEvery(actions.LOAD_AUDIENCE_DETAIL, LOAD_AUDIENCE_DETAIL),
    takeEvery(actions.LOAD_EVENT_STAT, LOAD_EVENT_STAT),
    takeEvery(actions.LOAD_EVENT_DATA, LOAD_EVENT_DATA),
    takeEvery(actions.LOAD_EVENT_LOCATIONS, LOAD_EVENT_LOCATIONS),
    takeEvery(actions.LOAD_SURFSIDE_AUDIENCE_INDEX, LOAD_SURFSIDE_AUDIENCE_INDEX),
  ])
}
