import actions from './actions'

const initialState = {
  videos: [],
  video_by_id:{}
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.LOAD_VIDEOS:
      return { ...state, videos: action.payload.data }
    case actions.LOAD_VIDEO_BY_ID:
      return { ...state, video_by_id: action.payload.data }
    default:
      return state
  }
}
