const actions = {
  SET_STATE: 'destination/SET_STATE',
  CREATE_DESTINATION: 'destination/CREATE_DESTINATION',
  LOAD_DESTINATIONS: 'destination/LOAD_DESTINATIONS',
  SET_DESTINATION: 'destination/SET_DESTINATION',
  LOAD_DESTINATION_BY_ID: 'destination/LOAD_DESTINATION_BY_ID',
  SET_DESTINATION_BY_ID: 'destination/SET_DESTINATION_BY_ID',
  UPDATE_DESTINATION_BY_ID: 'destination/UPDATE_DESTINATION_BY_ID'
}

export default actions
