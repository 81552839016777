import { all, put, call } from 'redux-saga/effects'
import { getLeftMenuData } from 'services/side-menu-top'

export function* GET_DATA() {
  const menuLeftData = yield call(getLeftMenuData)
  yield put({
    type: 'menu/SET_STATE',
    payload: {
      menuLeftData,
    },
  })
}

export function GET_LEFT_MENU_OPEN_STATE(isOpen) {
  return ({
    type: 'menu/GET_LEFT_MENU_OPEN_STATE',
    payload: {
      isOpen,
    },
  })
}

export default function* rootSaga() {
  yield all([
    GET_DATA(), // run once on app load to fetch menu data
  ])
}
