import { all, takeEvery, call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { createTarget, updateTarget, getTargetById, deleteTargetById, getTargets } from 'services/dsp-surfside-services'
import actions from './actions'

export function* CREATE_DSP_TARGET({ payload }) {
  const success = yield call(createTarget, payload)

  if (success) {
    notification.success({
      message: 'Target created',
      description: 'Target created successfully',
    })
  }else{
    notification.success({
      message: 'Error',
      description: 'Something went wrong',
    })
  }
}

export function* UPDATE_DSP_TARGET({ payload }) {
  const success = yield call(updateTarget, payload)
  if (success) {
    notification.success({
      message: 'Target updated',
      description: 'Target updated successfully',
    })
  }else{
    notification.success({
      message: 'Error',
      description: 'Something went wrong',
    })
  }
}

export function* GET_DSP_TARGET({ payload }) { 
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(getTargetById, {payload})
  if (response) {
    yield put({
      type: 'target/LOAD_TARGET_BY_ID',
      payload: response,
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export function* GET_DSP_TARGETS() {
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(getTargets)
  if (response) {
    yield put({
      type: 'target/LOAD_TARGETS',
      payload: response,
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export function* DELETE_TARGET(payload) {
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(deleteTargetById, payload)
  if (response) {
    yield put({
      type: 'target/GET_DSP_TARGETS',
      payload: response,
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_DSP_TARGET, CREATE_DSP_TARGET),
    takeEvery(actions.GET_DSP_TARGETS, GET_DSP_TARGETS),
    takeEvery(actions.GET_DSP_TARGET, GET_DSP_TARGET),
    takeEvery(actions.UPDATE_DSP_TARGET, UPDATE_DSP_TARGET),
    takeEvery(actions.DELETE_TARGET, DELETE_TARGET),

    
  ])
}

//  LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
