import actions from './actions'

const initialState = {
  loading: false,
  audiences: [],
  currentAudience: {
    audienceData: {
      audienceSummaryData: {},
      keyInsights: [],
      behaviorsDealSeekersStatsData: [],
      behaviorsAutomotiveStatsData: [],
      behaviorsPetsCardsData: [],
      behaviorsMomsData: {},
      cannabisPurchaseFrequencyData: [],
      cannabisStrainsCardsData: [],
      cannabisPurchaseMethodData: [],
      cannabisDesiredEffects: [],
      cannabisSymptomsData: [],
      cannabisProductTypeData: {},
      demographicAgeData: {},
      demographicEthnicityStatsData: [],
      demographicGenderCardsData: [],
      demographicMiscellaneousCardsData: [],
      householdDmaData: {},
      householdLengthOfResidenceData: [],
      householdHomeOwnershipsCardsData: [],
      deliveryTableData: [],
      behaviorsGeneralData: {},
      behaviorsMiscellaneousData: {},
      demographicOccupationData: {},
      financeData: {},
      purchaseRetailCategoriesData: {},
      purchaseRetailBrandsData: {},
      purchaseCpgCategoriesData: {},
      purchaseCpgBrandsData: {},
      purchaseCpgBuyStylesData: {},
      visitationDrugStoreData: {},
      visitationMassRetailData: {},
      visitationSpecialtyRetailData: {},
    },
    "audienceDetailData":{sections:[]},
    "audienceDestinations":{"data":[]}
  },
  eventCount: [{ totalCount: 0 }],
  deviceCount: [{ uniqueDevices: 0 }],
  userCount: [{ uniqueUsers: 0 }],
  digital: {
    slot: { data: [] },
    subSlot: { data: [] },
    deviceCountByType: [],
    userCount: [{ uniqueUsers: 0 }],
    deviceCount: [{ uniqueDevices: 0 }],
    eventCount: [{ totalCount: 0 }],
  },
  currentCampaign: {
    slot: { data: [] },
    subSlot: { data: [] },
    deviceCountByType: [],
    userCount: [{ uniqueUsers: 0 }],
    deviceCount: [{ uniqueDevices: 0 }],
    eventCount: [{ totalCount: 0 }],
  },
}

export default function surfservReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return {
        ...state,
        ...action.payload,
        currentAudience: {  
          ...state.currentAudience,
          ...action.payload.currentAudience
        },
        digital: { ...state.digital, ...action.payload.digital },
      }
    default:
      return state
  }
}
