const actions = {
  CREATE_DSP_VIDEO: 'video/CREATE_DSP_VIDEO',
  SET_STATE: 'video/SET_STATE',
  GET_DSP_VIDEOS: 'video/GET_DSP_VIDEOS',
  LOAD_VIDEOS: 'video/LOAD_VIDEOS',
  GET_DSP_VIDEO: 'video/GET_DSP_VIDEO',
  LOAD_VIDEO_BY_ID: 'video/LOAD_VIDEO_BY_ID',
  UPDATE_DSP_VIDEO: 'video/UPDATE_DSP_VIDEO',
  DELETE_VIDEO: 'video/DELETE_VIDEO',
}

export default actions
