/*eslint-disable */

import OverviewIcon from '../components/Icons/overviewIcon'
import CreateIcon from '../components/Icons/createIcon'
import SourcesIcon from '../components/Icons/sourcesIcon'
import AudiencesIcon from '../components/Icons/audiencesIcon'
import DestinationsIcon from '../components/Icons/destinationsIcon'
import InspectorIcon from '../components/Icons/inspectorIcon'
import ActivateIcon from '../components/Icons/activateIcon'
import MarketplacesIcon from '../components/Icons/marketplacesIcon'
import SettingsIcon from '../components/Icons/settingsIcon'
import SupportIcon from '../components/Icons/supportIcon'
import ComplianceIcon from '../components/Icons/complianceIcon'
import ReportIcon from '../components/Icons/reportIcon'

const menuData = [
  {
    title: 'Home',
    roles: ['admin', 'client'],
    key: 'SurfsideHome',
    url: '/home',
    icon: 'icmn icmn-home',
    $id: '/menu/home',
    staticIcon: OverviewIcon,
  },
  {
    title: 'Create',
    key: 'createNew',
    roles: ['admin', 'client'],
    icon: 'icmn icmn-plus',
    $id: '/menu/create',
    staticIcon: CreateIcon,
    children: [
      {
        key: 'createSource',
        title: 'Source',
        url: '/create/source',
        $id: '/menu/create/source',
      },
      {
        key: 'createAudience',
        title: 'Audience',
        url: '/create/audience',
        $id: '/menu/create/audience',
      },
      {
        key: 'createDestination',
        title: 'Destination',
        url: '/create/destination',
        $id: '/menu/create/destination',
      },
    ],
  },
  {
    title: 'Sources',
    roles: ['admin', 'client'],
    key: 'viewAllSource',
    icon: 'icmn icmn-enter utils__spin-delayed--pseudo-selector',
    url: '/all/sources',
    $id: '/menu/all/sources',
    staticIcon: SourcesIcon,
  },
  {
    title: 'Audiences',
    roles: ['admin'],
    key: 'viewAllAudience',
    icon: 'icmn icmn-users utils__spin-delayed--pseudo-selector',
    url: '/all/audiences',
    $id: '/menu/all/audiences',
    staticIcon: AudiencesIcon,
  },
  {
    title: 'Destinations',
    roles: ['admin'],
    key: 'viewAllDestinations',
    icon: 'icmn icmn-exit utils__spin-delayed--pseudo-selector',
    url: '/all/destinations',
    $id: '/menu/all/destinations',
    staticIcon: DestinationsIcon,
  },
  {
    title: 'Compliance',
    roles: ['admin'],
    key: 'viewCompliance',
    $id: '/menu/compliance',
    staticIcon: ComplianceIcon,
    children: [
      {
        key: 'viewDataSubjectRequests',
        title: 'Data Subject Requests',
        url: '/compliance/requests',
        $name: 'DataSubjectRequests',
        $id: '/menu/compliance/requests',
      },
      {
        key: 'viewComplianceSettings',
        title: 'Settings',
        url: '/compliance/settings',
        $id: '/menu/compliance/settings',
      },
    ],
  },
  // {
  //   title: 'Places',
  //   key: 'viewMotion',
  //   icon: 'icmn icmn-enter utils__spin-delayed--pseudo-selector',
  //   url: '/all/locations',
  //   $id: '/menu/all/locations',
  //   staticIcon: InspectorIcon,
  // },
  {
    title: 'Places',
    key: 'viewMotion',
    $name: 'AdLauncher',
    $id: '/menu/dsp',
    staticIcon: InspectorIcon,
    children: [
      {
        key: 'viewPointsOfInterest',
        title: 'Points of Interest',
        url: '/all/poi',
        $id: '/menu/all/poi',
      },
      {
        key: 'viewRetailInsights',
        title: 'Retail Insights',
        url: '/all/retail',
        $id: '/menu/all/retail',
      }
    ],
  },
  {
    title: 'Activate',
    roles: ['admin'],
    key: 'viewAdLauncher',
    icon: 'icmn icmn-binoculars',
    $name: 'AdLauncher',
    $id: '/menu/dsp',
    staticIcon: ActivateIcon,
    children: [
      {
        key: 'viewDSPCampaigns',
        title: 'Campaigns',
        url: '/dsp/campaigns',
        $id: '/menu/dsp/campaigns',
      },
      {
        key: 'viewDSPPlacements',
        title: 'Placements',
        url: '/dsp/placements',
        $id: '/menu/dsp/placements',
      },
      {
        key: 'viewDSPTargeting',
        title: 'Targeting',
        url: '/dsp/targeting',
        $id: '/menu/dsp/targeting',
      },
      {
        key: 'viewDSPRulesets',
        title: 'Rulesets',
        url: '/dsp/rulesets',
        $id: '/menu/dsp/rulesets',
      },
      {
        key: 'viewDSPCreative',
        title: 'Creative',
        url: '/dsp/creative',
        $id: '/menu/dsp/creative',
      },
      {
        key: 'viewDSPLists',
        title: 'Lists',
        url: '/dsp/lists',
        $id: '/menu/dsp/lists',
      }
    ],
  },
  {
    title: 'Marketplace',
    key: 'viewMarketplace',
    icon: 'icmn icmn-enter utils__spin-delayed--pseudo-selector',
    url: '/marketplace',
    $id: '/menu/marketplace',
    staticIcon: MarketplacesIcon,
  },
  {
    title: 'Reports',
    key: 'viewReports',
    icon: 'icmn icmn-enter utils__spin-delayed--pseudo-selector',
    url: '/reports',
    $id: '/menu/reports',
    staticIcon: ReportIcon,
  },
  // {
  // $skip: true,
  // divider: true,
  // },
  {
    title: 'Documentation',
    key: 'Documentation',
    $id: '/menu/settings',
    staticIcon: SupportIcon,
    children: [
      {
        $skip: true,
        key: 'Api',
        title: 'User Guide',
        url: 'https://docs.surfside.io',
        target: '_blank',
        $id: '/menu/docs/api',
      },
      {
        $skip: true,
        key: 'Api',
        title: 'API Docs',
        url: '/docs/api',
        $id: '/menu/docs/api',
      }
    ],
  },
  {
    title: 'Settings',
    key: 'settings',
    $id: '/menu/settings',
    staticIcon: SettingsIcon,
    children: [
      {
        $skip: true,
        key: 'admin',
        title: 'Authentication',
        $name: 'Admin',
        $id: '/menu/admin',
        children: [
          {
            $skip: true,
            key: 'apiKeys',
            title: 'API Keys',
            url: '/admin/apikeys',
            $id: '/menu/admin/apikeys',
          },
          {
            key: 'Credential Manager',
            title: 'Credentials',
            url: '/credentials',
            $name: 'CredentialManager',
            $id: '/menu/credentials',
          },
        ]
      },
      {
        key: 'User Management',
        title: 'Permissions',
        //url: '/users'
        $name: 'UserManagement',
        $id: '/menu/usermanagement',
        children: [
          {
            key: 'Account Management',
            title: 'Accounts',
            url: '/accounts',
            $name: 'AccountManagement',
            $id: '/menu/accounts',
          },
          {
            key: 'users',
            title: 'Users',
            url: '/usermanagement/users',
            $id: '/menu/usermanagement/users',
          },
          {
            key: 'roles',
            title: 'Roles',
            url: '/usermanagement/roles',
            $id: '/menu/usermanagement/roles',
          },
          {
            key: 'quotas',
            title: 'Quotas',
            url: '/usermanagement/quotas',
            $id: '/menu/usermanagement/quotas',
          },
          {
            key: 'privileges',
            title: 'Privileges',
            url: '/usermanagement/privileges',
            $id: '/menu/usermanagement/privileges',
          },
        ],
      },
      {
        $skip: true,
        key: 'Tags',
        title: 'Tags',
        url: '/tags',
        $name: 'Tags',
        $id: '/menu/tags',
      },
      {
        key: 'Location',
        title: 'Location Lists',
        $id: '/menu/location',
        $name: 'Location',
        children: [
          {
            key: 'Attribution',
            title: 'Attribution',
            url: '/attribution',
            $id: '/menu/location/attribution',
          },
          {
            key: 'Points of Interest',
            title: 'Points of Interest',
            url: '/location/poi',
            $name: 'PointsOfInterest',
            $id: '/menu/location/poi',
          },
        ],
      },
      {
        $skip: true,
        key: 'Documentation',
        title: 'Documentation',
        $id: '/menu/docs',
        children: [
          {
            $skip: true,
            key: 'Api',
            title: 'Api',
            url: '/docs/api',
            $id: '/menu/docs/api',
          }
        ]
      },
      {
        $skip: true,
        key: 'Metrics',
        title: 'Metrics',
        url: '/metrics',
        $name: 'Metrics',
        $id: '/menu/metrics',
        children: [
          {
          $skip: true,
          key: 'Usage',
          title: 'Usage',
          url: '/metrics/usage',
          $id: '/menu/metrics/usage'
          },
          {
          $skip: true,
          key: 'Bidder',
          title: 'Bidder',
          url: '/metrics/bidder',
          $id: '/menu/metrics/bidder'
          },
          {
            $skip: true,
            key: 'Reasons',
            title: 'Reasons',
            url: '/reasons',
            $name: 'Reasons',
            $id: '/menu/reasons',
          },
        ]
      },
    ],
  },
  // {
  //   divider: true,
  // }
]

export async function getLeftMenuData() {
  return secureMenu(menuData)
}

function secureMenu(tree, newTree = []) {
  for (let i = 0; i < tree.length; i++) {
    if (tree[i]) {
      //console.log(SD(tree[i]))
      if (tree[i].children) {
        secureMenu(tree[i].children)
      }
    }
  }
  return tree
}
