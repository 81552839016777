const actions = {
  CREATE_DSP_TARGET: 'target/CREATE_DSP_TARGET',
  SET_STATE: 'target/SET_STATE',
  GET_DSP_TARGETS: 'target/GET_DSP_TARGETS',
  LOAD_TARGETS: 'target/LOAD_TARGETS',
  GET_DSP_TARGET: 'target/GET_DSP_TARGET',
  LOAD_TARGET_BY_ID: 'target/LOAD_TARGET_BY_ID',
  UPDATE_DSP_TARGET: 'target/UPDATE_DSP_TARGET',
  DELETE_TARGET: 'target/DELETE_TARGET',
}

export default actions
