import { all, takeEvery, put, call } from 'redux-saga/effects'
import { currentAccount } from 'services/user-strapi'
import Cookies from 'js-cookie'
import actions from './actions'


export function* LOGIN({ payload }) {
  
  Cookies.set("auth0-data", payload)
  Cookies.set("auth0-jwt", payload.__raw)
  
  // console.log('payload', email, password)
  // yield call(login, "shawel.negussie", "Surfside!9")

  yield put({
    type: 'user/SET_STATE',
    payload: {...payload}
  })
}

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: true,
    },
  })
  const response = yield call(currentAccount)
  if (response) {
    const { uid: id, email, photoURL: avatar } = response
    yield put({
      type: 'user/SET_STATE',
      payload: {
        id,
        name: 'Administrator',
        email,
        avatar,
        role: 'admin',
        authorized: true,
      },
    })
  }
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
    },
  })
}

export function* LOGOUT() {
  
  // yield call(logout)
  yield put({
    type: 'user/SET_STATE',
    payload: {
      id: '',
      name: '',
      role: '',
      email: '',
      avatar: '',
      authorized: false,
      loading: false,
    },
  })

  Cookies.remove("auth0-data")
  Cookies.remove("auth0-jwt")
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    // LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
  ])
}
