import { all, takeEvery, call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { createRuleset, updateRuleset, getRulesetById, deleteRulesetById, getRulesets } from 'services/dsp-surfside-services'
import actions from './actions'

export function* CREATE_DSP_RULESET({ payload }) {
  const success = yield call(createRuleset, payload)

  if (success) {
    notification.success({
      message: 'Rule created',
      description: 'Rule created successfully',
    })
  }else{
    notification.success({
      message: 'Error',
      description: 'Something went wrong',
    })
  }
}

export function* UPDATE_DSP_RULESET({ payload }) {
  const success = yield call(updateRuleset, payload)
  if (success) {
    notification.success({
      message: 'Rule updated',
      description: 'Rule updated successfully',
    })
  }else{
    notification.success({
      message: 'Error',
      description: 'Something went wrong',
    })
  }
}

export function* GET_DSP_RULESET({ payload }) { 
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(getRulesetById, {payload})
  if (response) {
    yield put({
      type: 'ruleset/LOAD_RULE_BY_ID',
      payload: response,
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export function* GET_DSP_RULESETS() {
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(getRulesets)
  if (response) {
    yield put({
      type: 'ruleset/LOAD_RULES',
      payload: response,
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export function* DELETE_RULE(payload) {
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(deleteRulesetById, payload)
  if (response) {
    yield put({
      type: 'ruleset/GET_DSP_RULESETS',
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_DSP_RULESET, CREATE_DSP_RULESET),
    takeEvery(actions.GET_DSP_RULESETS, GET_DSP_RULESETS),
    takeEvery(actions.GET_DSP_RULESET, GET_DSP_RULESET),
    takeEvery(actions.UPDATE_DSP_RULESET, UPDATE_DSP_RULESET),
    takeEvery(actions.DELETE_RULE, DELETE_RULE),

    
  ])
}

//  LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
