import axios from 'axios'
import Cookies from 'js-cookie'

const SURFSIDE_ENDPOINT =
  process.env.REACT_APP_SURFSIDE_SERVICES_ENDPOINT || 'http://localhost:4000/api'

export async function createRuleset(payload) {
  console.log(payload)
  const response = await axios.request({
    method:'post',
    url: `${SURFSIDE_ENDPOINT}/dsp/rule`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
    data:payload.values
  });
  return response
}

export async function updateRuleset(payload) {
  const response = await axios.request({
    method:'put',
    url: `${SURFSIDE_ENDPOINT}/dsp/rule/${payload.ruleId}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
    data:payload.values
  });
  return response
}

export async function getRulesetById({payload}) {
  const response = await axios.request({
    method:'get',
    url: `${SURFSIDE_ENDPOINT}/dsp/rule/${payload}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}

export async function getRulesets() {
  const response = await axios.request({
    method:'get',
    url: `${SURFSIDE_ENDPOINT}/dsp/rule`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}

export async function deleteRulesetById(payload) {
  const response = await axios.request({
    method:'delete',
    url: `${SURFSIDE_ENDPOINT}/dsp/rule/${payload.payload}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}

// targets

export async function createTarget(payload) {
  const response = await axios.request({
    method:'post',
    url: `${SURFSIDE_ENDPOINT}/dsp/target`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
    data:payload.values
  });
  return response
}

export async function updateTarget(payload) {
  const response = await axios.request({
    method:'put',
    url: `${SURFSIDE_ENDPOINT}/dsp/target/${payload.targetId}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
    data:payload.values
  });
  return response
}

export async function getTargetById({payload}) {
  const response = await axios.request({
    method:'get',
    url: `${SURFSIDE_ENDPOINT}/dsp/target/${payload}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}

export async function getTargets() {
  const response = await axios.request({
    method:'get',
    url: `${SURFSIDE_ENDPOINT}/dsp/target`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}

export async function deleteTargetById(payload) {
  console.log(payload)
  const response = await axios.request({
    method:'delete',
    url: `${SURFSIDE_ENDPOINT}/dsp/target/${payload.payload}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}

// placements

export async function createCampaign(payload) {
  const response = await axios.request({
    method:'post',
    url: `${SURFSIDE_ENDPOINT}/dsp/placement`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
    data:payload.values
  });
  return response
}

export async function updateCampaign(payload) {
  const response = await axios.request({
    method:'put',
    url: `${SURFSIDE_ENDPOINT}/dsp/placement/${payload.campaignId}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
    data:payload.values
  });
  return response
}

export async function getCampaignById({payload}) {
  const response = await axios.request({
    method:'get',
    url: `${SURFSIDE_ENDPOINT}/dsp/placement/${payload}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}

export async function getCampaigns() {
  const response = await axios.request({
    method:'get',
    url: `${SURFSIDE_ENDPOINT}/dsp/placement`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}

export async function deleteCampaignById({payload}) {
  const response = await axios.request({
    method:'delete',
    url: `${SURFSIDE_ENDPOINT}/dsp/placement/${payload}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}


// banners

export async function createBanner(payload) {
  const response = await axios.request({
    method:'post',
    url: `${SURFSIDE_ENDPOINT}/dsp/banner`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
    data:payload.tvals
  });
  return response
}

export async function updateBanner(payload) {
  const response = await axios.request({
    method:'put',
    url: `${SURFSIDE_ENDPOINT}/dsp/banner/${payload.bannerId}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
    data:payload.tvals
  });
  return response
}

export async function getBannerById({payload}) {
  const response = await axios.request({
    method:'get',
    url: `${SURFSIDE_ENDPOINT}/dsp/banner/${payload}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}

export async function getBanners() {
  const response = await axios.request({
    method:'get',
    url: `${SURFSIDE_ENDPOINT}/dsp/banner`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}

export async function deleteBannerById(payload) {
  console.log(payload)
  const response = await axios.request({
    method:'delete',
    url: `${SURFSIDE_ENDPOINT}/dsp/banner/${payload.payload}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}

// videos

export async function createVideo(payload) {
  const response = await axios.request({
    method:'post',
    url: `${SURFSIDE_ENDPOINT}/dsp/video`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
    data:payload.tvals
  });
  return response
}

export async function updateVideo(payload) {
  const response = await axios.request({
    method:'put',
    url: `${SURFSIDE_ENDPOINT}/dsp/video/${payload.videoId}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
    data:payload.tvals
  });
  return response
}

export async function getVideoById({payload}) {
  const response = await axios.request({
    method:'get',
    url: `${SURFSIDE_ENDPOINT}/dsp/video/${payload}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}

export async function getVideos() {
  const response = await axios.request({
    method:'get',
    url: `${SURFSIDE_ENDPOINT}/dsp/video`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}

export async function deleteVideoById(payload) {
  console.log(payload)
  const response = await axios.request({
    method:'delete',
    url: `${SURFSIDE_ENDPOINT}/dsp/video/${payload.payload}`,
    headers:{'Authorization':`Bearer ${Cookies.get('jwt')}`},
  });
  return response
}
