const actions = {
  CREATE_DSP_CAMPAING: 'placement/CREATE_DSP_CAMPAIGN',
  SET_STATE: 'placement/SET_STATE',
  GET_DSP_CAMPAIGNS: 'placement/GET_DSP_CAMPAIGNS',
  LOAD_CAMPAIGNS: 'placement/LOAD_CAMPAINGS',
  GET_DSP_CAMPAIGN: 'placement/GET_DSP_CAMPAIGN',
  LOAD_CAMPAIGN_BY_ID: 'placement/LOAD_CAMPAIGN_BY_ID',
  UPDATE_DSP_CAMPAIGN: 'placement/UPDATE_DSP_CAMPAIGN',
  DELETE_CAMPAIGN: 'placement/DELETE_CAMPAIGN',
}

export default actions
