import actions from './actions'

const initialState = {
  destinations: [],
  destination_by_id:{}
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_DESTINATION:
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_DESTINATION:
      return { ...state, destinations: action.payload.data }
    case actions.SET_DESTINATION_BY_ID:
      return { ...state, destination_by_id: action.payload.data }
    default:
      return state
  }
}
