const actions = {
  SET_STATE: 'source/SET_STATE',
  CREATE_SOURCE: 'source/CREATE_SOURCE',
  LOAD_SOURCES: 'source/LOAD_SOURCES',
  SET_SOURCES: 'source/SET_SOURCES',
  GET_SOURCE_ACCOUNTS:'source/GET_SOURCE_ACCOUNTS',
  GET_SOURCE_COUNT_BY_TYPE:'source/GET_SOURCE_COUNT_BY_TYPE'
}

export default actions
