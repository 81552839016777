import { all, takeEvery, call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { createVideo, updateVideo, getVideoById, deleteVideoById, getVideos } from 'services/dsp-surfside-services'
import actions from './actions'

export function* CREATE_DSP_VIDEO({ payload }) {
  console.log(payload)
  const success = yield call(createVideo, payload)

  if (success) {
    notification.success({
      message: 'Video created',
      description: 'Video created successfully',
    })
  }else{
    notification.success({
      message: 'Error',
      description: 'Something went wrong',
    })
  }
}

export function* UPDATE_DSP_VIDEO({ payload }) {
  const success = yield call(updateVideo, payload)
  if (success) {
    notification.success({
      message: 'Video updated',
      description: 'Video updated successfully',
    })
  }else{
    notification.success({
      message: 'Error',
      description: 'Something went wrong',
    })
  }
}

export function* GET_DSP_VIDEO({ payload }) { 
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(getVideoById, {payload})
  if (response) {
    yield put({
      type: 'video/LOAD_VIDEO_BY_ID',
      payload: response,
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export function* GET_DSP_VIDEOS() {
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(getVideos)
  if (response) {
    yield put({
      type: 'video/LOAD_VIDEOS',
      payload: response,
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export function* DELETE_VIDEO(payload) {
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(deleteVideoById, payload)
  if (response) {
    yield put({
      type: 'video/GET_DSP_VIDEOS',
      payload: response,
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_DSP_VIDEO, CREATE_DSP_VIDEO),
    takeEvery(actions.GET_DSP_VIDEOS, GET_DSP_VIDEOS),
    takeEvery(actions.GET_DSP_VIDEO, GET_DSP_VIDEO),
    takeEvery(actions.UPDATE_DSP_VIDEO, UPDATE_DSP_VIDEO),
    takeEvery(actions.DELETE_VIDEO, DELETE_VIDEO),
    
  ])
}

//  LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
