import actions from './actions'

const initialState = {}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_SOURCE:
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SET_SOURCES:
      return { ...state, all:[...action.payload] }
    default:
      return state
  }
}
