import axios from 'axios'
import Cookies from 'js-cookie'

const SURFSIDE_ENDPOINT =
  process.env.REACT_APP_SURFSIDE_SERVICES_ENDPOINT || 'http://127.0.0.1:4000/api'

// SOURCES
export async function getSources(payload) {
  payload = payload || {}
  const response = await axios.request({
    method: 'POST',
    url: `${SURFSIDE_ENDPOINT}/sources`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
    data: payload,
  })
  return response
}

export async function createSource(payload) {
  const response = await axios.request({
    method: 'POST',
    url: `${SURFSIDE_ENDPOINT}/workflow/source/create`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
    data: payload,
  })

  return response
}

export async function getAccounts() {
  const response = await axios.request({
    method: 'get',
    url: `${SURFSIDE_ENDPOINT}/dmp/sources/accounts`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
  })

  return response
}

export async function getSourceCountByType() {
  const response = await axios.request({
    method: 'get',
    url: `${SURFSIDE_ENDPOINT}/dmp/sources/type/count`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
  })

  return response
}

export async function getAudiencesFromServices() {
  const response = await axios.request({
    method: 'get',
    url: `${SURFSIDE_ENDPOINT}/dmp/audiences`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
  })

  return response
}

export async function searchAudiences(payload) {
  const response = await axios.request({
    method: 'get',
    url: `${SURFSIDE_ENDPOINT}/audiences`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
    data: payload,
  })
  return response
}

export async function getAudienceDetail(audienceId) {
  const response = await axios.request({
    method: 'get',
    url: `${SURFSIDE_ENDPOINT}/dmp/audience/${audienceId}/legacy`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
  })
  return response
}

export async function createAudienceByService(payload) {
  const response = await axios.request({
    method: 'post',
    url: `${SURFSIDE_ENDPOINT}/audience`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
    data: payload,
  })
  return response
}

export async function updateAudienceDetail(audienceDetailId, payload) {
  const response = await axios.request({
    method: 'put',
    url: `${SURFSIDE_ENDPOINT}/audience/${audienceDetailId}`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
    data: payload,
  })
  return response
}

export async function updateAudienceDestination(payload) {
 
  const { audienceDetailId: adid, destinations } = payload
  const response = await axios.request({
    method: 'get',
    url: `${SURFSIDE_ENDPOINT}/dmp/audience-details/${adid}/destinations/update/${destinations.join()}`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
  })

  return response
}

// DESTINATIONS
export async function createDestination(payload) {
  const response = await axios.request({
    method: 'post',
    url: `${SURFSIDE_ENDPOINT}/destination`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
    data: payload,
  })
  return response
}

export async function loadDestinations() {
  const response = await axios.request({
    method: 'get',
    url: `${SURFSIDE_ENDPOINT}/dmp/destinations`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
  })
  return response
}

export async function getDestinationDetail(accountId, destinationId) {

  const response = await axios.request({
    method: 'get',
    url: `${SURFSIDE_ENDPOINT}/destination/${accountId}/${destinationId}`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
  })
  return response
}

export async function updateDestinationDetail(destinationId, payload) {
  const response = await axios.request({
    method: 'put',
    url: `${SURFSIDE_ENDPOINT}/destination/${destinationId}`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
    data: payload.submitData,
  })
  return response
}

// SOURCES
export async function getEventStats() {
  // return axios.get(`${SURFSIDE_ENDPOINT}/source/event/stats`)
  const response = await axios.request({
    method: 'get',
    url: `${SURFSIDE_ENDPOINT}/source/event/stats`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
  })

  return response
}

export async function postEventStatsForCampaign(query) {
  return axios.request({
    method: 'POST',
    url: `${SURFSIDE_ENDPOINT}/source/event/stats`,
    headers: { Authorization: `Bearer ${Cookies.get('auth0-jwt')}` },
    data: query,
  })
}

export async function getEventLocations(query) {
  return axios.post(`${SURFSIDE_ENDPOINT}/source/event/locations`, query, {
    Authorization: `Bearer ${Cookies.get('auth0-jwt')}`,
  })
}

export async function getSurfsideAudienceIndex(query) {
  return axios.post(`${SURFSIDE_ENDPOINT}/audience/index`, query)
}
