import { all /* spawn, call, delay */ } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import upload from './upload/sagas'
import rulesets from './dsp/rulesets/sagas'
import targets from './dsp/targets/sagas'
import campaigns from './dsp/campaigns/sagas'
import banners from './dsp/banners/sagas'
import videos from './dsp/videos/sagas'
import surfserv from './surfserv/sagas'
const sagas = [
  user(),
  menu(),
//  source(),
//  audience(),
  settings(),
 surfserv(),
  upload(),
 // destination(),
  rulesets(),
  targets(),
  campaigns(),
  banners(),
  videos(),
] //.map(makeRestartable)

export default function* rootSaga() {
  yield all(sagas)
}

/*
const makeRestartable = (saga) => {
  return function* () {
    yield spawn(function* () {
      while (true) {
        try {
          yield call(saga);
          console.error("unexpected root saga termination. The root sagas are supposed to be sagas that live during the whole app lifetime!"(),saga);
        } catch (e) {
          console.error("Saga error(), the saga will be restarted"(),e);
        }
        yield delay(1000); // Avoid infinite failures blocking app TODO use backoff retry policy...
      }
    })
  };
};
*/

/*
export default function* rootSaga() {
  yield all(sagas.map(saga => call(saga)))
}
*/
