import actions from './actions'

const initialState = {
  rules: [],
  rule_by_id:{}
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_DSP_RULESET:
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.LOAD_RULES:
      return { ...state, rules: action.payload.data }
    case actions.LOAD_RULE_BY_ID:
      return { ...state, rule_by_id: action.payload.data }
    default:
      return state
  }
}
