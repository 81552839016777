const actions = {
  CREATE_DSP_RULESET: 'ruleset/CREATE_DSP_RULESET',
  SET_STATE: 'ruleset/SET_STATE',
  GET_DSP_RULESETS: 'ruleset/GET_DSP_RULESETS',
  LOAD_RULES: 'ruleset/LOAD_RULES',
  GET_DSP_RULESET: 'ruleset/GET_DSP_RULESET',
  LOAD_RULE_BY_ID: 'ruleset/LOAD_RULE_BY_ID',
  UPDATE_DSP_RULESET: 'ruleset/UPDATE_DSP_RULESET',
  DELETE_RULE: 'ruleset/DELETE_RULE',
}

export default actions
