import actions from './actions'

const initialState = {
  banners: [],
  banner_by_id:{}
}

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.LOAD_BANNERS:
      return { ...state, banners: action.payload.data }
    case actions.LOAD_BANNER_BY_ID:
      return { ...state, banner_by_id: action.payload.data }
    default:
      return state
  }
}
