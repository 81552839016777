export const accounts = ({ surfserv: { sources } }) => {
  return Object.entries(
    sources.reduce((p, c) => {
      if (c.accountId) {
        p[c.accountId] = c.accountName || c.accountId
      }
      return p
    }, {}),
  ).filter(acc => acc[0])
}


export const account1 = () => ({ fake: 'fake' })
