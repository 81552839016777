import { all, takeEvery, call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { createBanner, updateBanner, getBannerById, deleteBannerById, getBanners } from 'services/dsp-surfside-services'
import actions from './actions'

export function* CREATE_DSP_BANNER({ payload }) {
  console.log(payload)
  const success = yield call(createBanner, payload)

  if (success) {
    notification.success({
      message: 'Banner created',
      description: 'Banner created successfully',
    })
  }else{
    notification.success({
      message: 'Error',
      description: 'Something went wrong',
    })
  }
}

export function* UPDATE_DSP_BANNER({ payload }) {
  const success = yield call(updateBanner, payload)
  if (success) {
    notification.success({
      message: 'Banner updated',
      description: 'Banner updated successfully',
    })
  }else{
    notification.success({
      message: 'Error',
      description: 'Something went wrong',
    })
  }
}

export function* GET_DSP_BANNER({ payload }) { 
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(getBannerById, {payload})
  if (response) {
    yield put({
      type: 'banner/LOAD_BANNER_BY_ID',
      payload: response,
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export function* GET_DSP_BANNERS() {
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(getBanners)
  if (response) {
    yield put({
      type: 'banner/LOAD_BANNERS',
      payload: response,
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export function* DELETE_BANNER(payload) {
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(deleteBannerById, payload)
  if (response) {
    yield put({
      type: 'banner/GET_DSP_BANNERS',
      payload: response,
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_DSP_BANNER, CREATE_DSP_BANNER),
    takeEvery(actions.GET_DSP_BANNERS, GET_DSP_BANNERS),
    takeEvery(actions.GET_DSP_BANNER, GET_DSP_BANNER),
    takeEvery(actions.UPDATE_DSP_BANNER, UPDATE_DSP_BANNER),
    takeEvery(actions.DELETE_BANNER, DELETE_BANNER),

    
  ])
}

//  LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
