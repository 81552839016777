import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { reducer as formReducer } from 'redux-form'
import source from './source/reducers'
import audience from './audience/reducers'
import destination from './destination/reducers'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import surfserv from './surfserv/reducers'
import ruleset from './dsp/rulesets/reducers'
import targets from './dsp/targets/reducers'
import campaigns from './dsp/campaigns/reducers'
import banners from './dsp/banners/reducers'
import videos from './dsp/videos/reducers'

const allReducers = history =>
  combineReducers({
    router: connectRouter(history),
    form: formReducer,
    user,
    source,
    audience,
    destination,
    menu,
    settings,
    surfserv,
    ruleset,
    targets,
    campaigns,
    banners,
    videos
  })

export default allReducers