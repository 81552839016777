const actions = {
  SET_STATE: 'audience/SET_STATE',
  CREATE_AUDIENCE: 'audience/CREATE_AUDIENCE',
  LOAD_AUDIENCE: 'audience/LOAD_AUDIENCE',
  SET_AUDIENCE: 'audience/SET_AUDIENCE',
  LOAD_AUDIENCES_FROM_SERVICES: 'audience/LOAD_AUDIENCES_FROM_SERVICES',
  LOAD_AUDIENCE_BY_ID: 'audience/LOAD_AUDIENCE_BY_ID',
  SET_AUDIENCE_BY_ID: 'audience/SET_AUDIENCE_BY_ID',
  UPDATE_AUDIENCE_BY_ID: 'audience/UPDATE_AUDIENCE_BY_ID',
  SEARCH_AUDIENCES: 'audience/SEARCH_AUDIENCES',
  OPEN_DESTINATION_MODAL: 'audience/OPEN_DESTINATION_MODAL',
  SET_DESTINATION_TO_AUDIENCE: 'audience/SET_DESTINATION_TO_AUDIENCE',
}

export default actions
