const actions = {
  CREATE_DSP_BANNER: 'banner/CREATE_DSP_BANNER',
  SET_STATE: 'banner/SET_STATE',
  GET_DSP_BANNERS: 'banner/GET_DSP_BANNERS',
  LOAD_BANNERS: 'banner/LOAD_BANNERS',
  GET_DSP_BANNER: 'banner/GET_DSP_BANNER',
  LOAD_BANNER_BY_ID: 'banner/LOAD_BANNER_BY_ID',
  UPDATE_DSP_BANNER: 'banner/UPDATE_DSP_BANNER',
  DELETE_BANNER: 'banner/DELETE_BANNER',
}

export default actions
