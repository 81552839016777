import { all, takeEvery, call } from 'redux-saga/effects'
import { notification } from 'antd'
import { upload } from 'services/upload-strapi'
import actions from './actions'

export function* UPLOAD(data) {
  console.log('test')
  const success = yield call(upload, data)
  console.log('success',success)
  if (success) {
    notification.success({
      message: 'Uploaded',
      description: 'You have successfully uploaded file',
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPLOAD, UPLOAD),
  ])
}
