import { all, takeEvery, call, put } from 'redux-saga/effects'
import { notification } from 'antd'
import { createCampaign, updateCampaign, getCampaignById, deleteCampaignById, getCampaigns } from 'services/dsp-surfside-services'
import actions from './actions'

export function* CREATE_DSP_CAMPAING({ payload }) {
  console.log('test')
  const success = yield call(createCampaign, payload)

  if (success) {
    notification.success({
      message: 'Campaign created',
      description: 'Campaign created successfully',
    })
  }else{
    notification.success({
      message: 'Error',
      description: 'Something went wrong',
    })
  }
}

export function* UPDATE_DSP_CAMPAIGN({ payload }) {
  const success = yield call(updateCampaign, payload)
  if (success) {
    notification.success({
      message: 'Campaign updated',
      description: 'Campaign updated successfully',
    })
  }else{
    notification.success({
      message: 'Error',
      description: 'Something went wrong',
    })
  }
}

export function* GET_DSP_CAMPAIGN({ payload }) {
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(getCampaignById, {payload})
  console.log(response)
  if (response) {
    yield put({
      type: 'placement/LOAD_CAMPAIGN_BY_ID',
      payload: response,
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export function* GET_DSP_CAMPAIGNS() {
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(getCampaigns)
  console.log(response)
  if (response) {
    yield put({
      type: 'placement/LOAD_CAMPAINGS',
      payload: response,
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export function* DELETE_CAMPAIGN(payload) {
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: true,
    },
  })
  const response = yield call(deleteCampaignById, payload)
  if (response) {
    yield put({
      type: 'placement/GET_DSP_CAMPAIGNS',
    })
  }
  yield put({
    type: 'dsp/SET_STATE',
    payload: {
      isFetching: false,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_DSP_CAMPAING, CREATE_DSP_CAMPAING),
    takeEvery(actions.GET_DSP_CAMPAIGNS, GET_DSP_CAMPAIGNS),
    takeEvery(actions.GET_DSP_CAMPAIGN, GET_DSP_CAMPAIGN),
    takeEvery(actions.UPDATE_DSP_CAMPAIGN, UPDATE_DSP_CAMPAIGN),
    takeEvery(actions.DELETE_CAMPAIGN, DELETE_CAMPAIGN),


  ])
}

//  LOAD_CURRENT_ACCOUNT(), // run once on app load to check user auth
