import React, { createContext, useState } from 'react';

export const QBContext = createContext(); // gets ability from provider

export const QBProvider = (props) => {
    const [config, setConfig] = useState({})
    return <QBContext.Provider value={[config, setConfig]} >
        {props.children}
    </QBContext.Provider>

}

export const QBConsumer = (props) => {
    return <QBContext.Consumer>
        {(data) => { return props.children(data) }}
    </QBContext.Consumer>
}

